<template>
  <div class="w-full lg:max-w-7xl md:mx-auto">
    <div class="px-4 sm:px-5 md:px-6 lg:px-8">
      <VHeader :title="$t('index.vehicles.title')" />
    </div>

    <div
      class="px-4 sm:px-5 md:px-6 lg:px-8 grid grid-cols-[repeat(4,_16rem)] lg:grid-cols-[repeat(4,_18rem)] py-4 mt-8 overflow-x-auto gap-4 lg:justify-around no-scrollbar"
    >
      <VCard
        v-for="(asset, index) in assets"
        :key="asset.subtype! + index"
        class="flex flex-col items-center p-6 border shadow-none"
      >
        <NuxtImg
          :src="asset.preview_image!"
          :alt="asset.name"
          loading="lazy"
          width="14rem"
          height="7rem"
          class="object-fit h-[6rem] drop-shadow-2xl lg:h-[7rem]"
        />

        <div class="grid w-full grid-flow-row pt-3 mt-auto gap-y-4 lg:gap-y-6">
          <div>
            <h2 class="text-lg font-semibold leading-5">
              {{ asset.name }}
            </h2>
            <div class="flex flex-wrap items-center mt-2 text-sm">
              <p class="text-sm">
                {{ $t('startingFrom') }}
                <span class="line-through text-cred">{{
                  asset.monthly_price_strike
                }}</span>
                <span>,&nbsp;</span>
              </p>
              <p class="font-medium">
                {{ asset.monthly_price }}
                <span class="font-medium text-gray-700"
                  >€/{{ $t('month') }}</span
                >
              </p>
            </div>
          </div>
          <div
            class="flex items-center px-2 py-1.5 text-green-700 bg-green-100 rounded-md"
          >
            <span class="text-xs font-semibold capitalize">
              {{ $t('funnel.vehicleAvailable') }}
            </span>
          </div>

          <VButton
            :variant="Variant.primary"
            :size="Size.sm"
            class="flex items-center w-full gap-3"
            @click="
              async () => {
                const url = await useFunnelRoute(asset.category)
                navigateTo(url)
              }
            "
          >
            {{ $t('index.vehicles.buttonCta') }}
            <IconsArrowRightIcon class="w-3 h-3" />
          </VButton>
        </div>
      </VCard>
    </div>
    <VButton
      :variant="Variant.link"
      :size="Size.lg"
      class="flex items-center gap-3 mx-auto text-lg text-cred"
      @click="navigateTo(funnelUrl)"
    >
      {{ $t('index.vehicles.allVehicles') }}
      <IconsArrowRightIcon class="w-3 h-3" />
    </VButton>
  </div>
</template>

<script setup lang="ts">
import type { Tables } from '@/types/database.types'
import { Size, Variant } from '@/types/enums'

defineProps<{
  assets: Tables<'asset_types'>[] | null
}>()

const funnelUrl = await useFunnelRoute()
</script>
