<template>
  <div class="flex justify-center">
    <VButton class="w-auto p-8 h-10 items-center flex" :size="Size.sm" @click="handleFindVehicle">
      <span class="font-semibold md:text-lg">{{
        $t('index.hero.findVehicle')
      }}</span>
    </VButton>
  </div>
</template>

<script setup lang="ts">
import { Size } from '@/types/enums'

const funnelRoute = await useFunnelRoute()
const experimentVariant = await useExperiment('frontpage-hero')

function handleFindVehicle() {
  const { $trackEvent } = useNuxtApp()
  $trackEvent('home.hero_cta.clicked', {
    variant: experimentVariant,
  })

  useRouter().push(funnelRoute)
}
</script>
