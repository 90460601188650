<template>
  <div>
    <div class="pb-12 md:hidden">
      <div class="flex justify-center p-4">
        <nuxt-img
          class="w-full rounded-lg"
          src="/home_hero_new_mobile.png"
          alt=""
        />
      </div>
      <div class="absolute w-64 p-2 -mt-16 bg-white z-9 rounded-tr-2xl">
        <h2 class="pl-2 text-4xl font-semibold">
          {{ $t('home.heroHeaderMobile[0]') }}
        </h2>
      </div>
      <div>
        <h2 class="max-w-xs pl-4 -mt-3 text-4xl font-semibold">
          {{ $t('home.heroHeaderMobile[1]') }}
        </h2>
      </div>

      <ul class="relative flex flex-col px-4 mt-5 text-cblack text-shadow-sm">
        <li
          v-for="(item, index) in heroItems"
          :key="'heroItem-' + index"
          class="flex items-center mb-4 space-x-2 text-lg"
        >
          <div
            class="flex items-center justify-center w-6 h-6 rounded-full bg-cred"
          >
            <CheckmarkIcon class="w-3 h-3 text-white" aria-hidden="true" />
          </div>
          <div>
            <span>{{ item.normalLine }}&nbsp;</span>
            <span class="font-bold">{{ item.boldLine }}&nbsp;</span>
            <span>{{ item.thirdLine }}&nbsp;</span>
          </div>
        </li>
      </ul>

      <div class="flex flex-col items-center mt-4 space-y-4 md:mb-6">
        <slot />
        <span class="text-gray-700">{{ $t('home.duration') }}</span>
      </div>
    </div>

    <div
      class="relative hidden px-6 pt-32 pb-32 text-center bg-cover lg:px-8 md:block md:shadow-md bg-hero md:pt-80 lg:pt-40"
      style="
        background-image: url('./home_hero_new.png');
        background-position: center right;
      "
    >
      <div
        class="absolute inset-0 pointer-events-none bg-cblack opacity-40"
      ></div>

      <div
        class="relative max-w-3xl px-6 mx-auto text-5xl font-bold text-white text-balance lg:max-w-5xl z-9 text-shadow-xl lg:text-7xl"
      >
        <h1>{{ $t('home.heroHeader') }}</h1>
      </div>

      <div class="relative flex justify-center mt-12 lg:mt-12 z-9">
        <ul
          class="flex max-w-6xl gap-4 text-base text-white lg:gap-6 lg:text-xl"
        >
          <li
            v-for="(item, index) in heroItems"
            :key="'heroItem-' + index"
            class="flex items-center space-x-4"
          >
            <div
              class="flex items-center justify-center rounded-full min-h-6 min-w-6 bg-cred"
            >
              <CheckmarkIcon class="w-3 h-3 text-white" aria-hidden="true" />
            </div>

            <div class="text-left text-balance">
              <span>{{ item.normalLine }}&nbsp;</span>
              <span class="font-bold">{{ item.boldLine }}&nbsp;</span>
              <span>{{ item.thirdLine }}&nbsp;</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="relative flex justify-center mt-12 md:mb-6 z-9">
        <slot />
      </div>
      <div class="relative mt-4 z-9">
        <span class="text-white">{{ $t('home.duration') }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue'

const { t } = useI18n()

const heroItems = [
  {
    normalLine: t('home.heroItemOne[0]'),
    boldLine: t('home.heroItemOne[1]'),
  },
  {
    normalLine: t('home.heroItemTwo[0]'),
    boldLine: t('home.heroItemTwo[1]'),
    thirdLine: t('home.heroItemTwo[2]'),
  },
  {
    normalLine: t('home.heroItemThree[0]'),
    boldLine: t('home.heroItemThree[1]'),
  },
]
</script>
