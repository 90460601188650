<template>
  <div class="px-4 overflow-x-auto no-scrollbar md:flex md:justify-center">
    <div class="flex items-center space-x-8 md:space-x-20 whitespace-nowrap">
      <img
        v-for="logo in logos"
        :key="logo.alt"
        :src="logo.src"
        :alt="logo.alt"
        class="w-auto h-6 md:h-6 sm:h-10"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Logo {
  src: string
  alt: string
}

const logos: Logo[] = [
  { src: '/references/evertaste_logo.png', alt: 'Evertaste' },
  { src: '/references/rewe_logo.png', alt: 'Rewe' },
  { src: '/references/arndt_logo.png', alt: 'Arndt' },
  { src: '/references/kluth_logo.png', alt: 'Kluth' },
  { src: '/references/fiege_logo.png', alt: 'Fiege' },
  { src: '/references/ludwig_logo.png', alt: 'Ludwig' },
  { src: '/references/db_schenker_logo.svg', alt: 'DB Schenker' },
  { src: '/references/gaffel_logo.png', alt: 'Gaffel' },
  { src: '/references/nosta_logo.png', alt: 'Nosta' },
  { src: '/references/samsung_logo.png', alt: 'Samsung' },
  { src: '/references/kaufland_logo.png', alt: 'Kaufland' },
  { src: '/references/talke_logo.png', alt: 'Talke' },
]
</script>
